import React from "react";
import "./ContentAndIconGrid.scss";
import RenderContent from "../../RenderContent";
import { ImagePass } from "../../Image/ImagePass";

export const ContentAndIconGrid = ({ iconGrid, content }) => {
  return (
    <section className="content-icon-grid">
      <div className="wrap">
        <div className="col--icons">
          {Object.keys(iconGrid).length > 0 &&
            iconGrid?.map((card, index) => {
              return (
                <div className="icon-card" key={index}>
                  <div className="icon-container">
                    <ImagePass src={card.icon} />
                  </div>
                  <RenderContent content={card.content} />
                </div>
              );
            })}
        </div>
        <div className="col--text">
          {content && <RenderContent content={content} />}
        </div>
      </div>
    </section>
  );
};
