import React from "react";
import { navigate } from "gatsby";
import { ImagePass } from "../../Image/ImagePass";

import "./icon-grid.scss";

export const IconGrid = (props) => {
  const { id, sectionTitle, sectionContent, grid } = props;

  const { backgroundColor, gridCellType, items } = grid;

  return (
    <div
      className={`icon-grid icon-grid--${backgroundColor}`}
      style={{ backgroundColor }}
    >
      <div className="wrap">
        {sectionTitle && sectionTitle.length > 0 && (
          <h2 className="heading">{sectionTitle}</h2>
        )}
        {sectionContent && sectionContent.length > 0 && (
          <div
            className="icon-grid--content"
            dangerouslySetInnerHTML={{ __html: sectionContent }}
          />
        )}
        {items.length > 0 && (
          <div className={`icon-grid--items grid-type-${gridCellType}`}>
            {Object.keys(items).length > 0 &&
              items?.map((item, idx) => {
                return (
                  <div
                    className={`icon-grid--item${
                      item.isItemOutlined ? " outlined" : ""
                    }${item.isItemLink ? " link" : ""}${` ${gridCellType}`}`}
                    key={`${id}-${idx}`}
                    onClick={
                      item.isItemLink ? () => navigate(item.link) : () => null
                    }
                  >
                    {item.backgroundImage && (
                      <ImagePass
                        src={item.backgroundImage}
                        alt={item.backgroundImage.title}
                        position="relative"
                        className={`icon-grid--item--image`}
                      />
                    )}
                    <div className="icon-grid--item--content">
                      <div className="icon-grid--item--icon-container">
                        <ImagePass
                          src={item.icon}
                          className="icon-grid--item--icon"
                          position="relative"
                          lazy={false}
                        />
                      </div>
                      <h3>{item.title}</h3>
                    </div>
                  </div>
                );
              })}
          </div>
        )}
      </div>
    </div>
  );
};
