import React, { useEffect } from "react";
import "./ProjectOverview.scss";
import RenderContent from "../../RenderContent";
import { ImagePass } from "../../Image/ImagePass";
import AOS from "aos";
import "aos/dist/aos.css";

export const ProjectOverview = ({
  projectIntro,
  projectFacts,
  additionalContent,
}) => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      <section className="project-overview">
        <div className="wrap">
          <div className="col col--large">
            <h2>Project Overview</h2>
            <RenderContent content={projectIntro} />
          </div>
          <div className="col col--small" data-aos="fade-left">
            {projectFacts && projectFacts.length > 0 && (
              <>
                <h2>Fast Facts</h2>
                <ul className="fact-list">
                  {Object.keys(projectFacts).length > 0 &&
                    projectFacts?.map((fact, index) => {
                      return (
                        <li className="fact-list-item" key={index}>
                          <span className="fact-list-label">{fact.label}</span>
                          {fact.content}
                        </li>
                      );
                    })}
                </ul>
              </>
            )}
          </div>
        </div>
      </section>
      {additionalContent && additionalContent.length > 0 && (
        <section className="project-content">
          <div className="wrap">
            <div className="col col--image">
              <div className="image-container">
                <ImagePass src={additionalContent[0].image} />
              </div>
            </div>
            <div className="col col--text">
              {additionalContent[0].heading && (
                <h2
                  dangerouslySetInnerHTML={{
                    __html: additionalContent[0].heading,
                  }}
                  className="content-title"
                />
              )}
              <div className="content-col">
                <RenderContent content={additionalContent[0].column1} />
              </div>
              {additionalContent[0].column2 && (
                <div className="content-col">
                  <RenderContent content={additionalContent[0].column2} />
                </div>
              )}
            </div>
          </div>
        </section>
      )}
    </>
  );
};
