import React, { useEffect } from "react";
import "./content-and-icon-list.scss";
import RenderContent from "../../RenderContent";
import { ImagePass } from "../../Image/ImagePass";
import AOS from "aos";
import "aos/dist/aos.css";

export const ContentAndIconList = ({ columnLeft, columnRight }) => {
  useEffect(() => {
    AOS.init();
  }, []);

  // im sorry
  // don't be sorry I'm sorry
  const colLeft = columnLeft[0];
  return (
    <section className="content-icon-list">
      <div className="wrap">
        <div className="col col--text">
          {colLeft.content && <RenderContent content={colLeft.content} />}
          {colLeft.featuredImage && (
            <div className="featured-image">
              <ImagePass src={colLeft.featuredImage} />
            </div>
          )}
        </div>
        <div className="col col--icons">
          {Object.keys(columnRight).length > 0 &&
            columnRight?.map((listItem, index) => {
              return (
                <div className="icon-card" key={index} data-aos="fade-left">
                  {listItem.icon && (
                    <div className="icon-card__icon">
                      <ImagePass src={listItem.icon} />
                    </div>
                  )}
                  <RenderContent content={listItem.content} />
                </div>
              );
            })}
          {colLeft.featuredImage && (
            <div className="featured-image featured-image--small">
              <ImagePass src={colLeft.featuredImage} />
            </div>
          )}
        </div>
      </div>
    </section>
  );
};
