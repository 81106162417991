import React from "react";
import * as AcfLayout from "../components/Acf";
import loadable from "@loadable/component";

const loadableComponents = ["TestimonialSlider"];

export const AcfComponent = ({
  location,
  componentName,
  item,
  pageContext,
  index,
  posts,
  date_format,
}) => {
  // If component does not exist in acf folder, print error message
  const ComponentName = loadableComponents.includes(componentName)
    ? loadable((props) =>
        import(`../components/Acf/${componentName}/index.jsx`)
      )
    : AcfLayout[componentName];

  const componentTitle = item.__typename
    ? item.__typename.replace("WordPressAcf_", "")
    : "No name";
  if (!ComponentName) {
    return null;
  } else {
    return (
      <ComponentName
        index={index}
        location={location}
        pageContext={pageContext}
        posts={posts}
        date_format={date_format}
        {...item}
      />
    );
  }
};
