import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

import "./TestimonialBlock.scss";
import RenderContent from "../../RenderContent";

export const TestimonialBlock = ({ testimonial, testimonialCaption }) => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <section className="testimonial-block">
      <div className="wrap">
        <div data-aos="fade-left">
          <RenderContent content={testimonial} />
          <span className="testimonial-caption">{testimonialCaption}</span>
        </div>
      </div>
    </section>
  );
};
